<template>
  <div class="hello">
    <!-- <a-spin size="large" :spinning="isLoading" tip="分配中..."> -->

    <div class="top" ref="top" style="display: flex; flex-wrap: wrap;">
      <div style="display: flex; align-items: center;flex-wrap: wrap;">
        <label class="label_top" style="margin-left: 15px;">金额：</label>
        <input class="input_money" title="每组金额" @input="enterMoney" v-model="inputMoney"
          :style="{ width: inputMoneyWidth }" style="min-width: 60px; " type="number" @keyup.enter="getParts">
        <label class="label_top">&nbsp;* 49&nbsp;</label>
        <label class="label_top" style="word-wrap: break-word;">= {{ sumMoney }}</label>
      </div>
      <div style="display: flex; align-items: center;flex-wrap: wrap;">
        <label class="label_top" style="margin-left: 15px;">分配组数：</label>
        <input class="input_money" title="分配组数" @input="enterClass" v-model="inputClass"
          :style="{ width: inputClassWidth }" type="number" @keyup.enter="getParts">
      </div>
      <div style="display: flex; align-items: center;flex-wrap: wrap;">
        <label class="label_top" style="margin-left: 15px;">倍数：</label>
        <input class="input_money" title="分配后金额倍数" @input="enterMul" v-model="inputMul" :style="{ width: inputMulWidth }"
          type="number" @keyup.enter="getParts">
      </div>
      <button class="btn_jisuan" @click="getParts" style="margin-left: 30px;">重新分配</button>
    </div>
    <div class="contain" :key="containKey" ref="contain" :style="{ marginTop: containMarginTopHeight + 'px' }">

      <div class="hunk" v-for="num in 12" :key="num">
        <div class="hunk_num">{{ getCnNum(num) }}</div>
        <div class="hunk_contain">
          <div class="row" v-for="n in 1 + Math.floor((49 - num) / 12)" :key="n">
            <label class="row_num">{{ num + (n - 1) * 12 }}</label>
            <div class="row_contain">
              <div class="row_money " :class="{ 'row_money_hover': isHovered(item) }"
                v-bind:style="{ width: moneyWidth + 'px' }" v-for="item in results[num + (n - 1) * 12 - 1]" :key="item"
                v-on:mouseenter="mouseEnter(item, $event)" @mouseleave="handleMouseLeave" @click="clickNum(item)">
                <div class="inner">{{ item }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a-modal v-model:open="visible" :title="`${wantFindNum}`" @ok="handleOk">
        <template #footer>
          <a-button key="submit" type="primary" @click="handleOk">
            关闭
          </a-button>
        </template>
        <a-select show-search :value="wantFindNum" placeholder="input search text"
          style="width: 100px;margin-right: 16px;" @search="wantFindNameHandleSearch" @change="wantFindNameHandleChange"
          size="small">
          <template v-for="(rs, index) in results">
            <a-select-option v-for="(r, i) in rs" :key="index + '-' + i" :value="r">{{ r }}</a-select-option>
          </template>

        </a-select>
        <a-radio-group v-model:value="modelRadioGroupValue" button-style="solid" @change="modelRadioGroupChange"
          size="small">
          <a-radio-button value="10">
            {{ visible == true ? "仅行排序" : "" }}
          </a-radio-button>
          <a-radio-button value="20">
            {{ visible == true ? "块+行排序" : "" }}
          </a-radio-button>
        </a-radio-group>
        <a-divider orientation="left"></a-divider>
        <div :key="modelContainKey" style="display: flex; flex-wrap: wrap;">
          <a-popover trigger="focus" v-for="item in sortedFindNums" :key="item"
            :title="`${getCnNum(item.hunk)} - ${item.row}`">
            <template #content>
              <a-tag v-for="num in results[item.row - 1]" :key="num" :color="num == wantFindNum ? 'blue' : undefined">
                {{ num }}</a-tag>
            </template>

            <a-button type="primary" size="small"
              style="margin-right: 8px;margin-bottom: 8px;display: flex; align-items: center;">
              {{ item["row"] }}<a-badge v-if="item['time'] > 1" style="margin-left: 2px;" :count="`${item['time']}`"
                size="small"></a-badge>
            </a-button>

          </a-popover>
        </div>

      </a-modal>
    </div>
    <!-- </a-spin> -->
  </div>
</template>

<script>
export default {
  name: 'RandomNum',
  props: {
    msg: String
  },
  data() {
    return {
      inputMoney: 2000,
      inputClass: 5,
      inputMul: 5,
      inputMoneyWidth: '70px',
      inputClassWidth: '50px',
      inputMulWidth: '50px',
      results: [],
      cnNum: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十", "十一", "十二"],
      hoverMoney: 0,
      moneyWidth: 10,
      isLoading: false,
      containKey: 0,
      visible: false,//弹窗显示
      wantFindNum: 0,//想要寻找的数字
      findNums: [],//根据点击的数字找出的元素
      modelRadioGroupValue: '20',//弹窗显示顺序按钮组选的值
      modelContainKey: 0,
      containMarginTopHeight:70,//为了适配移动端
    };
  },
  mounted() {
    // 获取 top 元素高度
    console.log(this.$refs.top.offsetHeight)
    const topHeight = this.$refs.top.offsetHeight;
    // 将 top 元素高度赋值给 contain 元素的 margin-top 属性
    this.containMarginTopHeight=topHeight+20
  },
  watch: {
    results() {
      this.containKey += 1;//刷新containKey，触发contain局部重新加载
      // this.isLoading=false;//数组变化后关闭加载状态
    },
    inputMoney() {
      // console.log(this.inputMoney,newVal)
      const len = this.inputMoney.toString().length;
      this.inputMoneyWidth = `${len * 10 + 30}px`; // 每个字符设置为 10 像素
    },
    inputClass() {
      const len = this.inputClass.toString().length;
      this.inputClassWidth = `${len * 10 + 30}px`; // 每个字符设置为 10 像素
    },
    inputMul() {
      const len = this.inputMul.toString().length;
      this.inputMulWidth = `${len * 10 + 30}px`; // 每个字符设置为 10 像素
    },
  },
  computed: {
    // 计算属性，用于判断是否添加 'row_money_hover' 类名
    hoveredItemClass() {
      return this.hoverMoney !== null ? 'row_money_hover' : '';
    },

    //计算金额总计
    sumMoney() {
      return this.inputMoney * 49
    },
    //弹窗根据hunk及row排序
    sortedFindNums() {
      var findNums = this.findNums
      return findNums.sort((a, b) => {
        // 首先根据 hunk 升序排序
        if (this.modelRadioGroupValue == 20) {
          if (a.hunk !== b.hunk) {
            return a.hunk - b.hunk;
          }
        }
        // 如果 hunk 相同，则根据 row 升序排序
        return a.row - b.row;
      });
    },
  },
  methods: {
    enterMoney(event) {
      console.log("event", event.target.value, "input", this.inputMoney)
      const inputText = event.target.value;  // 获取用户输入的文本
      const regExp = /^([1-9]\d*)?$/; // 正则表达式，用于匹配大于等于 1 的正整数
      if (regExp.test(inputText)) {
        this.inputMoney = inputText;  // 符合要求，将文本转换为数值型变量
      } else {
        event.target.value = this.inputMoney || '';  // 不符合要求，禁止该输入，并还原之前的值
      }
    },
    //输入组数
    enterClass() {

    },
    //输入倍数
    enterMul() {

    },
    //生成全局随机数
    getParts() {
      this.isLoading = true;//开始生成时开始加载
      if (this.inputMul * this.inputClass > this.inputMoney) {
        alert("分配组数 * 金额倍数 > 分配金额")
        return
      }
      //生成随机数
      const ratios = [];
      for (let i = 0; i < 49; i++) {
        let r = []
        for (let j = 0; j < this.inputClass; j++) {
          r.push(Math.random() * 10 + 1);
        }
        ratios.push(r);
      }

      //计算每组随机数的和
      let totalRatios = []
      ratios.forEach(r => {
        totalRatios.push(r.reduce((acc, cur) => acc + cur))
      });

      //计算每个随机数占的比例
      let partRatios = ratios.map((rs, index) => rs.map(r => r / totalRatios[index] * 100))

      //计算最后分配后的数
      let afters = []
      ratios.forEach((rs, i) => {
        let as = [], shengyu = this.inputMoney
        rs.forEach((r, j, rs) => {
          if (j >= rs.length - 1) {
            as.push(shengyu)
          } else {
            let a = Math.max(this.inputMul, Math.round(this.inputMoney * partRatios[i][j] * 0.01 / this.inputMul) * this.inputMul)
            as.push(a)
            shengyu -= a
          }
        })
        if (shengyu <= 0) {
          // alert(shengyu)
          afters.push(this.getpart())
        } else {
          afters.push(as)
        }
      }
      )
      // console.log(Math.floor(r * 27 * 0.01 * 0.2) * 5)
      console.log(ratios, totalRatios, partRatios, afters)
      // console.log(partRatios)
      // this.results = afters
      this.results.length = 0
      this.results = afters//赋值给页面data
      this.setWidth()//设置数字宽度一致
      this.isLoading = false


    },
    //生成一行随机数
    getpart() {
      //生成一组随机数
      let rs = []
      for (let j = 0; j < this.inputClass; j++) {
        rs.push(Math.random() * 10 + 1);
      }
      //合成总数
      let totalRatio = rs.reduce((acc, cur) => acc + cur)

      //获取每个数比例
      let partRatio = rs.map((r) => Math.max(1, r / totalRatio * 100))

      //生成目标数组
      let result = [], shengyu = this.inputMoney;
      // console.log(shengyu)
      rs.forEach((r, index, rs) => {
        if (index >= rs.length - 1) {
          result.push(shengyu);
        } else {
          let a = Math.max(this.inputMul, Math.round(this.inputMoney * partRatio[index] * 0.01 / this.inputMul) * this.inputMul)
          if (typeof a === 'number' && !isNaN(a)) { // 判断是否为数字
            result.push(a);
            shengyu -= a;
          } else {
            console.log('计算错误', partRatio[index]);
          }
          // console.log(shengyu)
        }
      });
      // console.log(shengyu)
      console.log("result", result)
      result = shengyu > 0 ? result : this.getpart();
      // console.log(result)
      return result

    },
    //获取中文名称
    getCnNum(num) {
      return this.cnNum[num - 1]
    },
    mouseEnter(num) {
      this.hoverMoney = num
      console.log(this.hoverMoney)
    },
    handleMouseLeave() {
      this.hoverMoney = -1
    },
    // 判断当前 item 是否应该添加 'row_money_hover' 类名
    isHovered(item) {
      return item === this.hoverMoney;
    },
    //设置宽度
    setWidth() {
      let weishu = Math.ceil(Math.log10(this.inputMoney / this.inputClass)) + 1

      // 获取所有子元素
      const rowMoneyEls = document.querySelectorAll('.row_money')

      //将数字长度设置为整除的位数+1的宽度
      rowMoneyEls.forEach(el => {
        el.style.width = `${weishu * 10}px`;
      });

      this.moneyWidth = weishu * 10

      // // 计算最大宽度
      // let maxWidth = 0
      // rowMoneyEls.forEach(el => {
      //     const width = el.querySelector('.inner').offsetWidth
      //     if (width > maxWidth) {
      //         maxWidth = width
      //     }
      // })

      // console.log("maxWidth", maxWidth)
      // // 将最大宽度赋值给所有包裹元素
      // rowMoneyEls.forEach(el => {
      //     // el.style.width = `${maxWidth}px`;
      // });
    },
    //显示弹窗
    clickNum(item) {
      var clickNum = item;//找出数字
      this.wantFindNum = clickNum
      console.log("点击的数字是:", clickNum)
      this.visible = true;//显示弹窗
      this.findNumsByNum(clickNum)//根据数字设置找到所在的位置

    },
    //输入数字，从results找到符合并复制给findNums
    findNumsByNum(num) {
      var findNums = []
      //遍历数组,找到有num的行的行号以及数量
      this.results.forEach((rs, index) => {
        var time = rs.filter(item => item == num).length
        // console.log("time：",time)
        if (time >= 1) {
          var row = { hunk: index % 12 + 1, row: index + 1, time: time }
          findNums.push(row)
        }
      });
      this.findNums = findNums
      console.log(findNums)
    },
    //弹窗显示顺序按钮组
    modelRadioGroupChange(e) {
      this.modelRadioGroupValue = e.target.value
      console.log(e.target.value, this.modelRadioGroupValue)
      this.modelContainKey++
    },
    //弹窗修改要查找的值
    modelChangeWantFindNum(e) {
      console.log(e)
    },
    //点击确认
    handleOk(e) {
      console.log(e);
      this.visible = false;

    },
    //修改要查找的数字
    wantFindNameHandleSearch() {

    },
    wantFindNameHandleChange(value) {
      console.log(value);
      this.wantFindNum = value;
      this.findNumsByNum(value)
      this.modelContainKey++
    },
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  padding: 0;
  margin: 0;
}



.top {
  position: fixed;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(48, 96, 255, 0.123);
  top: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 4px;
}

.contain {
  /*margin-top: 70px;*/
}

.input_money {
  /* width: auto; */
  padding: 4px;
  width: 60px;

  border-radius: 4px;
  border-color: #2857ff38;
  font-weight: 700;
  font-size: 20px;
  color: #2857FF;
}

.input_money:focus {
  outline: none;
  /* border-color: #2857ff; */
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.6);
}

.btn_jisuan {
  background-color: #2857FF;
  border: none;
  color: #FFFFFF;
  border-radius: 4px;
  font-size: 18px;
  padding: 4px 8px 4px 8px;
}

.btn_jisuan:hover {
  outline: none;
  background-color: #5f81fc;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.6);
}

.hunk {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 12px;
  align-self: stretch;
}

.hunk_num {
  width: 40px;
  border-right: 2px #000;
}

.hunk_contain {
  display: flex;
  flex-direction: column;
  gap: 5px;

}

.row {
  display: flex;
  flex-direction: row;
  gap: 42px;
  width: 100%;
  align-self: stretch;
}

.row_num {
  min-width: 20px;
  width: 20px;
  text-align: center;
}

.row_contain {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.row_money {
  flex: 1;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner {
  width: max-content;
  text-align: center;
}

.row_money:hover {
  background-color: antiquewhite;
}

.row_money_hover {
  background-color: antiquewhite;
}</style>
